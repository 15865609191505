// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from 'next/dynamic';

export const components = {
  advisory_board: dynamic(() => import('./AdvisoryBoard')),
  band: dynamic(() => import('./Band')),
  bento_grid: dynamic(() => import('./BentoGrid')),
  blog_content: dynamic(() => import('./BlogContent')),
  blog_header: dynamic(() => import('./BlogHeader')),
  blog_listing: dynamic(() => import('./BlogListing')),
  bottom_cta: dynamic(() => import('./BottomCta')),
  call_booking_form: dynamic(() => import('./CallBookingForm')),
  card_grid_with_search: dynamic(() => import('./CardGridWithSearch')),
  cards: dynamic(() => import('./Cards')),
  chips: dynamic(() => import('./Chips')),
  collapse_panel: dynamic(() => import('./CollapsePanel')),
  content_fold: dynamic(() => import('./ContentFold')),
  customer_info: dynamic(() => import('./CustomerInfo')),
  customer_testimonials: dynamic(() => import('./CustomerTestimonials')),
  deep_developer_profile: dynamic(() => import('./DeepDeveloperProfile')),
  developer_cards: dynamic(() => import('./DeveloperCards')),
  developer_stories: dynamic(() => import('./DeveloperStories')),
  developer_video_reviews: dynamic(() => import('./VideoSlider')),
  dual_cta: dynamic(() => import('./DualCta')),
  explore: dynamic(() => import('./Explore')),
  faq: dynamic(() => import('./Faq')),
  footer_cards: dynamic(() => import('./FooterCards')),
  footer_cta_fold: dynamic(() => import('./FooterCtaFold')),
  full_bleed_feature: dynamic(() => import('./FullBleedFeature')),
  hero: dynamic(() => import('./Hero')),
  hire_profiles: dynamic(() => import('./HireProfiles')),
  hire_team: dynamic(() => import('./HireTeam')),
  icon_list: dynamic(() => import('./IconList')),
  icons_grid: dynamic(() => import('./IconsGrid')),
  industries: dynamic(() => import('./Industries')),
  info_collapse_cards: dynamic(() => import('./InfoCollapseCards')),
  info_display_cards: dynamic(() => import('./InfoDisplayCards')),
  info_display_personas: dynamic(() => import('./InfoDisplayPersonas')),
  interlinking_cards: dynamic(() => import('./InterlinkingCards')),
  interview_questions: dynamic(() => import('./InterviewQuestions')),
  investors: dynamic(() => import('./Investors')),
  job_description: dynamic(() => import('./JobDescription')),
  job_description_article: dynamic(() => import('./JobDescriptionArticle')),
  job_listing: dynamic(() => import('./JobListing')),
  logos: dynamic(() => import('./Logos')),
  logos_carousel: dynamic(() => import('./LogosCarousel')),
  news: dynamic(() => import('./News')),
  offerings: dynamic(() => import('./Offerings')),
  ordered_cards: dynamic(() => import('./OrderedCards')),
  overview: dynamic(() => import('./Overview')),
  partner_logo: dynamic(() => import('./PartnerLogo')),
  posts: dynamic(() => import('./Posts')),
  q_and_a_columns: dynamic(() => import('./QAndAColumns')),
  remote_job_showcase: dynamic(() => import('./RemoteJobShowcase')),
  remote_work_guide: dynamic(() => import('./RemoteWorkGuide')),
  resources_carousel: dynamic(() => import('./ResourcesCarousel')),
  resume_content: dynamic(() => import('./ResumeContent')),
  review_content: dynamic(() => import('./ReviewContent')),
  scrolling_steps: dynamic(() => import('./ScrollingSteps')),
  search: dynamic(() => import('./Search')),
  services_overview: dynamic(() => import('./ServicesOverview')),
  services_seo_section: dynamic(() => import('./ContentGrid')),
  simple_cta: dynamic(() => import('./SimpleCta')),
  social_accounts: dynamic(() => import('./SocialAccounts')),
  stats: dynamic(() => import('./Stats')),
  stats_media: dynamic(() => import('./StatsMedia')),
  status: dynamic(() => import('./Status')),
  story: dynamic(() => import('./Story')),
  subscription_cta: dynamic(() => import('./SubscriptionCta')),
  table_of_content: dynamic(() => import('./TableOfContent')),
  team: dynamic(() => import('./Team')),
  testimonial: dynamic(() => import('./Testimonial')),
  text: dynamic(() => import('./Text')),
  timeline: dynamic(() => import('./Timeline')),
  toggler: dynamic(() => import('./Toggler')),
  top_devs: dynamic(() => import('./TopDevs')),
  top_jobs: dynamic(() => import('./TopJobs')),
  turing_metrics: dynamic(() => import('./TuringMetrics')),
  use_case_content: dynamic(() => import('./UseCaseContent')),
  vertical_tabs: dynamic(() => import('./VerticalTabs')),
  video_block: dynamic(() => import('./Media')),
  video_summary: dynamic(() => import('./VideoSummary')),
  world_stats: dynamic(() => import('./WorldStats')),
};
